<template>
  <!-- 商品规格列表每一项 -->
  <div class="goodsSpecList">
    <div class="goodsSpecItem" v-for="(item, index) in goodsUnit" :key="index">
      <div class="left">
        <!-- 表格区域 -->
        <el-table :data="item.goodsUnits" style="width: 100%" border class="table">
          <el-table-column align="center" label="序号" width="50">
            <template>
              <div>{{ index + 1 }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="barcode" align="center" label="商品条码" min-width="200">
            <template slot="header">
              <i style="color: #ff4949">*</i>
              商品条码
            </template>
            <template slot-scope="scope">
              <el-form-item v-if="index === 0">
                <el-input v-model="scope.row.barcode" disabled maxlength="20"></el-input>
              </el-form-item>
              <el-form-item v-if="!(index === 0)" :prop="`goodsUnit[${index}].goodsUnits[${scope.$index}].barcode`"
                :rules="rules[`goodsUnit.goodsUnits.barcode`]">
                <el-input v-model="scope.row.barcode" maxlength="20"></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column prop="purPrice" align="center" label="进价(元)">
            <template slot="header">
              <i style="color: #ff4949">*</i>
              进价(元)
            </template>
            <template slot-scope="scope">
              <el-form-item v-if="index === 0">
                <el-input v-model="scope.row.purPrice" disabled type="number" maxlength="7"></el-input>
              </el-form-item>
              <el-form-item v-if="!(index === 0)" :prop="`goodsUnit[${index}].goodsUnits[${scope.$index}].purPrice`"
                :rules="rules[`goodsUnit.goodsUnits.purPrice`]">
                <el-input v-model="scope.row.purPrice" type="number" maxlength="7"></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column prop="salePrice" align="center" label="售价(元)">
            <template slot="header">
              <i style="color: #ff4949">*</i>
              售价(元)
            </template>
            <template slot-scope="scope">
              <el-form-item v-if="index === 0">
                <el-input v-model="scope.row.salePrice" disabled type="number" maxlength="7"></el-input>
              </el-form-item>
              <el-form-item v-if="!(index === 0)" :prop="`goodsUnit[${index}].goodsUnits[${scope.$index}].salePrice`"
                :rules="rules[`goodsUnit.goodsUnits.salePrice`]">
                <el-input v-model="scope.row.salePrice" type="number" maxlength="7"></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column prop="unitName" align="center" label="单位">
            <template slot="header">
              <i style="color: #ff4949">*</i>
              单位
            </template>
            <template slot-scope="scope">
              <el-form-item v-if="index === 0">
                <el-input v-model="scope.row.unitName" disabled></el-input>
              </el-form-item>
              <el-form-item v-if="!(index === 0)" :prop="`goodsUnit[${index}].goodsUnits[${scope.$index}].unitName`"
                :rules="rules[`goodsUnit.goodsUnits.unitName`]">
                <div class="x-f">
                  <el-select v-model="scope.row.unitName" placeholder="请选择" @blur="
                      $parent.$parent.validateField(
                        `goodsUnit[${index}].goodsUnits[${scope.$index}].unitName`
                      )
                    " @change="unitNameChange($event, scope.row)">
                    <el-option v-for="item in unitList" :key="item.dictCode" :label="item.dictLabel"
                      :value="item.dictLabel">
                    </el-option>
                  </el-select>
                  <i class="el-icon-edit marL10" style="color: #85cafc; fontsize: 16px; cursor: pointer"
                    @click="setUnit"></i>
                </div>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column prop="goodsSpec" align="center" label="规格">
            <template slot="header">
              <i style="color: #ff4949">*</i>
              规格
            </template>
            <template slot-scope="scope">
              <el-form-item v-if="index === 0">
                <el-input v-model="scope.row.goodsSpec" :disabled="index === 0"></el-input>
              </el-form-item>
              <el-form-item v-else :prop="`goodsUnit[${index}].goodsUnits[${scope.$index}].goodsSpec`"
                :rules="rules[`goodsUnit.goodsUnits.goodsSpec`]">
                <el-input v-model="scope.row.goodsSpec"></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column prop="noConvert" align="center" label="换算关系" v-if="index != 0">
            <template slot-scope="scope">
              <el-form-item :prop="`goodsUnit[${index}].goodsUnits[${scope.$index}].noConvert`">
                <el-switch @change="noConvertSwitch($event, scope.row)" v-model="scope.row.noConvert"
                  :disabled="scope.row.createTime && scope.row.noConvert == 1" :active-value="0" :inactive-value="1">
                </el-switch>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column prop="" align="center" label="换算单位" v-if="index !== 0" width="400">
            <template slot="header">
              <i style="color: #ff4949">*</i>
              换算单位
            </template>
            <template slot-scope="scope">
              <div class="x-fc referUnit">
                <el-input v-model="scope.row.unitName" placeholder="" disabled></el-input>
                <span> = </span>
                <el-form-item :prop="`goodsUnit[${index}].goodsUnits[${scope.$index}].referUnitAmount`"
                  :rules="rules[`goodsUnit.goodsUnits.referUnitAmount`]">
                  <el-input v-model="scope.row.referUnitAmount" :disabled="scope.row.noConvert == 1"
                    type="number"></el-input>
                </el-form-item>
                <el-form-item :prop="`goodsUnit[${index}].goodsUnits[${scope.$index}].referUnitId`"
                  :rules="rules[`goodsUnit.goodsUnits.referUnitId`]">
                  <el-select v-model="scope.row.referUnitId" :disabled="scope.row.noConvert == 1" placeholder="请选择"
                    @blur="
                      $parent.$parent.validateField(
                        `goodsUnit[${index}].goodsUnits[${scope.$index}].referUnitId`
                      )
                    " style="width: 100%" @focus="refreshReferUnitData(index)">
                    <el-option v-for="item in goodsUnit
                        .filter(
                          (x, i) =>
                            scope.row.unitId !== x.goodsUnits[0].unitId &&
                            i < index
                        )
                        .map((x) => ({
                          unitName: `${x.goodsUnits[0].goodsSpec}/${x.goodsUnits[0].unitName}`,
                          referUnitId: x.goodsUnits[0].unitId,
                        }))" :key="item.referUnitId" :label="item.unitName" :value="item.referUnitId">
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <!-- 商品属性列表 -->
        <el-form-item label="商品属性:" label-width="75px" class="marT10">
          <div class="machiningList">
            <div class="machiningItem marT10" v-for="(v, i) in item.machiningGroups" :key="i">
              <div class="x-f">
                <el-select @change="changeMachining(v)" size="mini" v-model="v.machiningGroupId" placeholder="请选择"
                  @focus="filterMachiningData(index)">
                  <el-option v-for="item in curMachiningData" :key="item.machiningGroupId"
                    :label="item.machiningGroupName" :value="item.machiningGroupId">
                  </el-option>
                </el-select>
                <el-button class="marL10" type="text" @click="handleMachiningGroup('del', index, i)">删除</el-button>
              </div>
              <div class="marT10 x-fs">
                <el-tag v-for="(j, x) in v.machiningItems" :key="j.machiningId" class="marR10 marB10" closable
                  type="info" color="#f2f2f2" @close="handleMachining('del', index, i, x)">
                  {{ j.machiningName + " ￥" + j.machiningPrice }}</el-tag>
                <el-button class="addMachiningBtn" size="mini" icon="el-icon-plus" v-if="v.machiningGroupId" @click="
                    handleMachining('add', index, i, '', v.machiningGroupId)
                  "></el-button>
              </div>
            </div>
          </div>
          <el-button size="mini" class="el-icon-plus" @click="handleMachiningGroup('add', index)">添加属性</el-button>
        </el-form-item>
        <!-- 搭配商品 -->
        <el-form-item label="搭配商品:" label-width="75px" class="marT10 x-x daPeiGoods">
          <el-button size="mini" v-if="item.collocationItems.length <= 0" class="el-icon-plus"
            @click="handleCollocationTableRow('push', index, 0)">添加商品</el-button>
          <!-- 搭配商品表格 -->
          <el-table v-if="item.collocationItems.length > 0" :data="item.collocationItems" border
            class="collocationTable marT10 flex1" @cell-mouse-enter="cellMouseEnter($event, index)"
            @cell-mouse-leave="cellMouseLeave(index)">
            <el-table-column align="center" width="80">
              <template v-slot="scope">
                <i v-if="scope.row.hoverRow" @click="
                    handleCollocationTableRow('push', index, scope.$index)
                  " class="el-icon-circle-plus operatePush"></i>
                <i v-if="scope.row.hoverRow" @click="handleCollocationTableRow('del', index, scope.$index)"
                  class="el-icon-remove operateDel"></i>
                <div v-if="!scope.row.hoverRow">{{ scope.$index + 1 }}</div>
              </template>
            </el-table-column>
            <el-table-column label="商品编码" width="260" align="center" prop="goodsNo">
              <template slot="header">
                <i style="color: #ff4949">*</i>
                商品编码
              </template>
              <template slot-scope="scope">
                <el-form-item :prop="`goodsUnit[${index}].collocationItems[${scope.$index}].goodsNo`"
                  :rules="rules[`goodsUnit.collocationItems.goodsNo`]">
                  <el-input v-model="scope.row.goodsNo" @keyup.enter.native="
                      collocationTableKeyup($event, index, scope.$index)
                    ">
                    <i slot="suffix" class="el-icon-more more" @click="openSelectGoods(index)"></i>
                  </el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column label="商品名称" align="center" prop="goodsName" show-overflow-tooltip width="200">
            </el-table-column>
            <el-table-column label="商品条码" width="200" align="center" prop="barcode" show-overflow-tooltip>
            </el-table-column>
            <el-table-column label="规格" width="160" align="center" prop="goodsSpec" show-overflow-tooltip>
            </el-table-column>
            <el-table-column label="单位" width="160" align="center" prop="unitId" show-overflow-tooltip>
              <template slot="header">
                <i style="color: #ff4949">*</i>
                单位
              </template>
              <template slot-scope="scope">
                <el-form-item :prop="`goodsUnit[${index}].collocationItems[${scope.$index}].unitId`"
                  :rules="rules[`goodsUnit.collocationItems.unitId`]">
                  <el-select v-model="scope.row.unitId" @blur="
                      $parent.$parent.validateField(
                        `goodsUnit[${index}].collocationItems[${scope.$index}].unitId`
                      )
                    " @change="changeUnitPrice(scope.row)">
                    <el-option v-for="item in scope.row.units" :key="item.unitId" :label="item.unitName"
                      :value="item.unitId" />
                  </el-select>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column label="标准售价(元)" width="120" align="center" prop="salePrice" show-overflow-tooltip>
            </el-table-column>
            <el-table-column label="数量" align="center" prop="goodsQty" show-overflow-tooltip>
              <template slot="header">
                <i style="color: #ff4949">*</i>
                数量
              </template>
              <template slot-scope="scope">
                <el-form-item :prop="`goodsUnit[${index}].collocationItems[${scope.$index}].goodsQty`"
                  :rules="rules[`goodsUnit.collocationItems.goodsQty`]">
                  <el-input v-model="scope.row.goodsQty"> </el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column label="搭配价" align="center" prop="goodsPrice" show-overflow-tooltip>
              <template slot="header">
                <i style="color: #ff4949">*</i>
                搭配价
              </template>
              <template slot-scope="scope">
                <el-form-item :prop="`goodsUnit[${index}].collocationItems[${scope.$index}].goodsPrice`"
                  :rules="rules[`goodsUnit.collocationItems.goodsPrice`]">
                  <el-input v-model="scope.row.goodsPrice"> </el-input>
                </el-form-item>
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>

        <!-- 搭配营养物质 -->
        <el-form-item label="营养成分:" label-width="75px" class="marT10 x-x daPeiGoods">
          <el-button size="mini" class="el-icon-plus" v-if="
              item.goodsUnitNutrientReqList &&
              item.goodsUnitNutrientReqList.length <= 0
            " @click="handleNutrientTableRow('push', index, 0)">添加营养成分</el-button>
          <!-- 搭配营养物质表格 -->
          <el-table border v-if="
              item.goodsUnitNutrientReqList &&
              item.goodsUnitNutrientReqList.length > 0
            " :data="item.goodsUnitNutrientReqList" tooltip-effect="dark" class="collocationTable marT10 flex1"
            :before-close="handleClose" @cell-mouse-enter="cellMouseEnterNutrient($event, index)"
            @cell-mouse-leave="cellMouseLeaveNutrient(index)">
            <el-table-column align="center" width="80">
              <template v-slot="scope">
                <i v-if="scope.row.hoverRow" @click="handleNutrientTableRow('push', index, scope.$index)"
                  class="el-icon-circle-plus operatePush"></i>
                <i v-if="scope.row.hoverRow" @click="handleNutrientTableRow('del', index, scope.$index)"
                  class="el-icon-remove operateDel"></i>
                <div v-if="!scope.row.hoverRow">{{ scope.$index + 1 }}</div>
              </template>
            </el-table-column>

            <el-table-column prop="nutrientName" label="营养物质" align="center">
              <template slot="header">
                <i style="color: #ff4949">*</i>
                营养物质
              </template>
              <template slot-scope="scope">
                <el-input v-model="scope.row.nutrientName" :readonly="true">
                  <i slot="suffix" class="el-icon-more more nutrient" @click="openNutrients(index)"></i>
                </el-input>
              </template>
            </el-table-column>
            <el-table-column label="数量" align="center" prop="nutrientQty" show-overflow-tooltip>
              <template slot="header">
                <i style="color: #ff4949">*</i>
                数量
              </template>
              <template slot-scope="scope">
                <el-form-item :prop="`goodsUnit[${index}].goodsUnitNutrientReqList[${scope.$index}].nutrientQty`"
                  :rules="
                    rules[`goodsUnit.goodsUnitNutrientReqList.nutrientQty`]
                  ">
                  <el-input v-model="scope.row.nutrientQty" type="number">
                  </el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column label="单位" align="center" prop="nutrientUnit" show-overflow-tooltip>
              <template slot="header">
                <i style="color: #ff4949">*</i>
                单位
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
      </div>
      <div class="right" v-if="index !== 0">
        <i class="el-icon-circle-close" @click="delGoodsSpec(index)"></i>
      </div>
    </div>
    <!-- 搭配营养物质弹窗 -->
    <c-dialog title="营养物质" :width="600" :showDialog.sync="dialogVisible">
      <template #content>
        <el-form ref="refnutrientsForm" :model="nutrientsForm" :rules="nutrientsFormRules">
          <el-table :data="nutrientsForm.tableData" border style="width: 100%" ref="multipleTable"
            @cell-mouse-enter="cellMouseEnterDialog" @cell-mouse-leave="cellMouseLeaveDialog"
            @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55" align="center">
            </el-table-column>
            <el-table-column align="center" :label="tableCellLabelDialog" width="80">
              <template v-slot="scope">
                <i v-if="scope.row.hoverRow" @click="rowAvailable('push', scope.$index)"
                  class="el-icon-circle-plus operatePush"></i>
                <i v-if="scope.row.hoverRow" @click="rowAvailable('del', scope.$index)"
                  class="el-icon-remove operateDel"></i>
                <div v-if="!scope.row.hoverRow">{{ scope.$index + 1 }}</div>
              </template>
            </el-table-column>

            <el-table-column prop="nutrientName" label="营养物质" width="280px" align="center">
              <template slot-scope="scope">
                <el-form-item :prop="'tableData.' + scope.$index + '.nutrientName'"
                  :rules="nutrientsFormRules[`tableData.nutrientName`]">
                  <el-input v-model="scope.row.nutrientName" width="120px" />
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column prop="nutrientUnit" label="单位" align="center">
              <template slot-scope="scope">
                <el-form-item :prop="'tableData.' + scope.$index + '.nutrientUnit'"
                  :rules="nutrientsFormRules[`tableData.nutrientUnit`]">
                  <el-input v-model="scope.row.nutrientUnit" width="40px" />
                </el-form-item>
              </template>
            </el-table-column>
          </el-table>
        </el-form>
      </template>
      <template #buttonList>
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="updateGoodNutrient">保 存</el-button>
      </template>
    </c-dialog>
  </div>
</template>

<script>
import {
  nutrientListAPI,
  updateNutrientAPI,
  deleteNutrientAPI
} from "@/api/system/goods/infoDetail.js";
import CDialog from "@/components/dialogTemplate/dialog/index.vue";
import { deepCopy } from "@/utils";
import { getUid } from "@/utils/index.js";
export default {
  name: "GoodsSpecItem",
  components: {
    CDialog,
  },
  props: {
    //规格数组
    goodsUnit: {
      type: Array,
    },
    //属性数据
    machiningData: {
      type: Array,
    },
    //单位列表数据
    unitList: {
      type: Array,
    },
    //商品
    goodsId: {
      type: String || Number,
    },
  },
  created() {
    console.log("规格====", this.unitList);
  },
  data() {
    // 规则定义
    let referUnitValidator = (rule, value, callback) => {
      if (value == 0) {
        callback(new Error("换算数量不能为 0"));
      } else {
        callback();
      }
    };
    return {
      delReqList: [],
      tableCellLabelDialog: "序号",
      multipleSelection: [], //选中营养物质弹窗
      dialogVisible: false, //弹窗开关
      //营业物质弹窗
      nutrientsForm: {
        tableData: [{}],
      },
      //营业物质弹窗校验
      nutrientsFormRules: {
        "tableData.nutrientName": [
          {
            required: true,
            message: "请输入营养物质",
            trigger: ["blur", "change"],
          },
        ],
        "tableData.nutrientUnit": [
          {
            required: true,
            message: "请输入单位",
            trigger: ["blur", "change"],
          },
        ],
      },
      curSpecIndex: undefined,
      //当前商品规格的属性数据
      curMachiningData: [],
      //表单校验
      rules: {
        // 商品规格表格
        "goodsUnit.goodsUnits.barcode": [
          {
            required: true,
            message: "请输入条码",
            trigger: ["blur", "change"],
          },
        ],
        "goodsUnit.goodsUnits.purPrice": [
          {
            required: true,
            message: "请输入进价",
            trigger: ["blur", "change"],
          },
        ],
        "goodsUnit.goodsUnits.salePrice": [
          {
            required: true,
            message: "请输入售价",
            trigger: ["blur", "change"],
          },
        ],
        "goodsUnit.goodsUnits.unitName": [
          {
            required: true,
            message: "请选择单位",
            trigger: ["blur", "change"],
          },
        ],
        "goodsUnit.goodsUnits.goodsSpec": [
          {
            required: true,
            message: "请输入规格",
            trigger: "blur",
          },
        ],
        "goodsUnit.goodsUnits.referUnitAmount": [
          {
            required: true,
            message: "请输入换算数量",
            trigger: ["blur", "change"],
          },
          {
            required: true,
            validator: referUnitValidator,
            trigger: ["blur", "change"],
          },
        ],
        "goodsUnit.goodsUnits.referUnitId": [
          {
            required: true,
            message: "请选择换算单位",
            trigger: ["blur", "change"],
          },
        ],
        //搭配商品表格
        "goodsUnit.collocationItems.goodsNo": [
          {
            required: true,
            message: "请选择商品",
            trigger: ["blur", "change"],
          },
        ],
        "goodsUnit.collocationItems.unitId": [
          {
            required: true,
            message: "请选择单位",
            trigger: ["blur", "change"],
          },
        ],
        "goodsUnit.collocationItems.goodsQty": [
          {
            required: true,
            message: "请输入数量",
            trigger: ["blur", "change"],
          },
        ],
        "goodsUnit.goodsUnitNutrientReqList.nutrientQty": [
          {
            required: true,
            message: "请输入数量",
            trigger: ["blur", "change"],
          },
        ],
        "goodsUnit.collocationItems.goodsPrice": [
          {
            required: true,
            message: "请输入搭配价",
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  watch: {
    //侦听传过来的属性数据 赋值当前规格属性数组
    machiningData: {
      handler(val) {
        if (val) this.curMachiningData = val;
      },
      immediate: true,
    },
  },
  methods: {
    changeMachining(v){
      this.$set(v, 'machiningItems', [])
    },
    //换算单位
    noConvertSwitch(value, row) {
      if (value == 1) {
        this.$set(row, "referUnitAmount", 1);
        this.$set(row, "referUnitId", 0);
      }
    },
    //切换单位
    unitNameChange(val, row) {
      if (!row.val) {
        this.$set(row, "goodsSpec", val);
      }
    },
    //表格行hover时,显示操作加减号
    cellMouseEnterDialog(row) {
      let eleIndex = this.nutrientsForm.tableData.indexOf(row);
      for (
        let index = 0;
        index < this.nutrientsForm.tableData.length;
        index++
      ) {
        const element = this.nutrientsForm.tableData[index];
        if (eleIndex == index) {
          element["hoverRow"] = true;
        } else {
          element["hoverRow"] = false;
        }
      }
      this.tableCellLabelDialog = "操作";
    },
    //表格行离开hover时,不显示操作加减号,显示序号
    cellMouseLeaveDialog() {
      // 移除hover的事件
      for (
        let index = 0;
        index < this.nutrientsForm.tableData.length;
        index++
      ) {
        const element = this.nutrientsForm.tableData[index];
        element["hoverRow"] = false;
      }
      this.tableCellLabelDialog = "序号";
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    async updateGoodNutrient() {
      // if (!this.isDataSelected) {
      //   // 如果没有数据被选中，显示提示信息
      //   this.$message.error('未选中，请选至少一条数据')
      //   return
      // }
      this.$refs.refnutrientsForm.validate(async (valid) => {
        if (valid) {
          console.log("multipleSelection", this.multipleSelection);
          this.nutrientsForm.tableData.forEach((item) => {
            const isBol = this.multipleSelection.some(
              (v) => v.nutrientCacheId == item.nutrientCacheId
            );
            item.isSelect = isBol ? 0 : 1;
          });

          // 将新增和修改的数据发送到后端
          let updateReqList = this.nutrientsForm.tableData.filter(
            (v) => v.nutrientId
          );

          let addReqList = this.nutrientsForm.tableData.filter(
            (v) => !v.nutrientId
          );

          const requestData = {
            addReqList,
            updateReqList,
            delReqList: this.delReqList,
          };
          try {
            const res = await updateNutrientAPI(requestData);
            if (res.code === 200) {
              this.dialogVisible = false;
              this.goodsUnit[this.curSpecIndex].goodsUnitNutrientReqList =
                res.data?.filter((item) => item.isSelect == 0);
              this.$message.success("保存成功");
            }
          } catch (error) {
            this.$message.error("保存失败，发生异常，请检查网络连接");
          }
        }
      });
    },
    async openNutrients(index) {
      this.curSpecIndex = index;
      this.delReqList = [];
      this.dialogVisible = true;
      const res2 = await nutrientListAPI();
      let list = deepCopy(
        this.goodsUnit[this.curSpecIndex].goodsUnitNutrientReqList
      );
      this.nutrientsForm.tableData = res2.data;
      if (this.nutrientsForm.tableData.length === 0) {
        this.nutrientsForm.tableData = [
          {
            nutrientCacheId: getUid(), // 新增的数据通常没有ID，根据后端要求填写默认值
            nutrientName: "",
            nutrientUnit: "",
          },
        ]; // 添加一个空对象作为新的最后一行
      } else {
        this.nutrientsForm.tableData.forEach((item) => {
          item.nutrientCacheId = getUid();
          if (list.some((v) => v.nutrientId == item.nutrientId)) {
            console.log("list2", list);
            this.$nextTick(() => {
              this.$refs.multipleTable.toggleRowSelection(item);
            });
          }
        });
      }
    },
    handleClose() {
      this.dialogVisible = false;
    },
    //营养成分新增或删除
    //表格增加/减少一行方法
    rowAvailable(name, index) {
      if (name === "push") {
        this.nutrientsForm.tableData.splice(index + 1, 0, {
          nutrientCacheId: getUid(), // 新增的数据通常没有ID，根据后端要求填写默认值
          nutrientName: "",
          nutrientUnit: "",
        });
      } else {
        if (this.nutrientsForm.tableData.length <= 1) {
          this.nutrientsForm.tableData = [
            {
              nutrientCacheId: getUid(), // 新增的数据通常没有ID，根据后端要求填写默认值
              nutrientName: "",
              nutrientUnit: "",
            },
          ];
        } else {
          if (this.nutrientsForm.tableData[index].nutrientId) {
            this.delReqList.push(this.nutrientsForm.tableData[index]);
          }
          this.nutrientsForm.tableData.splice(index, 1);
        }
      }
    },
    //操作属性组(新增/删除)  type新增/删除  specListIndex 规则列表下标   MGIndex 属性组下标
    handleMachiningGroup(type, specListIndex, MGIndex) {
      let obj = {
        type: type,
        specListIndex: specListIndex,
      };
      if (MGIndex) obj.MGIndex = MGIndex;
      this.$emit("handleAddMachiningGroup", obj);
    },
    //操作属性(新增/删除)  type新增/删除  specListIndex 规则列表下标 MGIndex 属性组下标  MIndex 属性下标  id 属性组id
    handleMachining(type, specListIndex, MGIndex, MIndex, id) {
      let obj = {
        type: type,
        specListIndex: specListIndex,
        MGIndex: MGIndex,
      };
      if (MIndex) obj.MIndex = MIndex;
      if (id) obj.id = id;
      this.$emit("handleAddMachining", obj);
      //删除属性组时,
    },
    //删除商品规格  index规格列表下标
    delGoodsSpec(index) {
      const delItem = this.goodsUnit[index]; // referUnitId
      if (
        this.goodsUnit.find(
          (x, i) =>
            delItem.goodsUnits[0].referUnitId && i !== index &&
            x.goodsUnits[0].referUnitId === delItem.goodsUnits[0].referUnitId
        )
      ) {
        return this.$message.error("该单位被其他单位换算，无法删除！");
      }
      this.$emit("delGoodsSpec", index);
    },
    //设置单位
    setUnit() {
      this.$emit("setUnit");
    },
    //刷新换算单位数据   index当前规格数组下标
    refreshReferUnitData(index) {
      this.$emit("refreshReferUnitData", index);
    },
    //过滤当前规格属性数组中已选中的属性  index当前规格下标
    filterMachiningData(index) {
      this.curMachiningData = this.machiningData.filter((item) => {
        let bool = this.goodsUnit[index].machiningGroups.every(
          (v) => item.machiningGroupId !== v.machiningGroupId
        );
        return bool;
      });
    },
    //搭配商品表格
    //表格行hover时,显示操作加减号
    cellMouseEnter(row, index) {
      //获取当前行下标
      let eleIndex = this.goodsUnit[index].collocationItems.indexOf(row);
      // 注意必须是使用两次深拷贝 因为 hoverRow 属性不是tableData原有的 则直接修改无效  所以两次深拷贝重新赋值
      let Arr = JSON.parse(
        JSON.stringify(this.goodsUnit[index].collocationItems)
      );
      for (let index = 0; index < Arr.length; index++) {
        const element = Arr[index];
        if (eleIndex == index) {
          element["hoverRow"] = true;
        } else {
          element["hoverRow"] = false;
        }
      }
      this.goodsUnit[index].collocationItems = JSON.parse(JSON.stringify(Arr));
    },
    //表格行离开hover时,不显示操作加减号,显示序号
    cellMouseLeave(index) {
      // 移除hover的事件
      for (let i = 0; i < this.goodsUnit[index].collocationItems.length; i++) {
        const element = this.goodsUnit[index].collocationItems[i];
        element["hoverRow"] = false;
      }
    },
    //搭配营养物质表格
    //表格行hover时,显示操作加减号
    cellMouseEnterNutrient(row, index) {
      //获取当前行下标
      let eleIndex =
        this.goodsUnit[index].goodsUnitNutrientReqList.indexOf(row);
      // 注意必须是使用两次深拷贝 因为 hoverRow 属性不是tableData原有的 则直接修改无效  所以两次深拷贝重新赋值
      let Arr = JSON.parse(
        JSON.stringify(this.goodsUnit[index].goodsUnitNutrientReqList)
      );
      for (let index = 0; index < Arr.length; index++) {
        const element = Arr[index];
        if (eleIndex == index) {
          element["hoverRow"] = true;
        } else {
          element["hoverRow"] = false;
        }
      }
      this.goodsUnit[index].goodsUnitNutrientReqList = JSON.parse(
        JSON.stringify(Arr)
      );
    },
    //表格行离开hover时,不显示操作加减号,显示序号
    cellMouseLeaveNutrient(index) {
      // 移除hover的事件
      for (
        let i = 0;
        i < this.goodsUnit[index].goodsUnitNutrientReqList.length;
        i++
      ) {
        const element = this.goodsUnit[index].goodsUnitNutrientReqList[i];
        element["hoverRow"] = false;
      }
    },
    //打开选择商品弹窗
    openSelectGoods(index) {
      this.$emit("openSelectGoods", index);
    },
    //商品单位更改 售价跟着改变  (搭配商品表格)
    changeUnitPrice(row) {
      let item = row.units.find((v) => v.unitId === row.unitId);
      row.salePrice = item.salePrice;
      row.goodsSpec = item.unitName;
    },
    //操作搭配商品表格 type(增加行/减少行) specIndex规格列表下标  index当前表格行下标
    handleCollocationTableRow(type, specIndex, index) {
      let obj = {
        type: type,
        specIndex: specIndex,
        index: index,
      };
      this.$emit("handleCollocationTableRow", obj);
    },
    //操作搭配商品表格 type(增加行/减少行) specIndex规格列表下标  index当前表格行下标
    handleNutrientTableRow(type, specIndex, index) {
      let obj = {
        type: type,
        specIndex: specIndex,
        index: index,
      };
      this.$emit("handleNutrientTableRow", obj);
    },
    //搭配商品表格 商品编码键盘回车事件  event输入框值 specIndex规格列表下标  index当前表格行下标
    collocationTableKeyup(event, specIndex, index) {
      let obj = {
        val: event.target.value,
        specIndex: specIndex,
        index: index,
      };
      this.$emit("collocationTableKeyup", obj);
    },

    //操作搭配营养物质表格 type(增加行/减少行) specIndex规格列表下标  index当前表格行下标
    handleNutrientsTableRow(type, specIndex, index) {
      let obj = {
        type: type,
        specIndex: specIndex,
        index: index,
      };
      this.$emit("handleNutrientsTableRow", obj);
    },
    //搭配商品表格
    //表格行hover时,显示操作加减号
    cellMouseEnter(row, index) {
      //获取当前行下标
      let eleIndex = this.goodsUnit[index].collocationItems.indexOf(row);
      // 注意必须是使用两次深拷贝 因为 hoverRow 属性不是tableData原有的 则直接修改无效  所以两次深拷贝重新赋值
      let Arr = JSON.parse(
        JSON.stringify(this.goodsUnit[index].collocationItems)
      );
      for (let index = 0; index < Arr.length; index++) {
        const element = Arr[index];
        if (eleIndex == index) {
          element["hoverRow"] = true;
        } else {
          element["hoverRow"] = false;
        }
      }
      this.goodsUnit[index].collocationItems = JSON.parse(JSON.stringify(Arr));
    },
    //表格行离开hover时,不显示操作加减号,显示序号
    cellMouseLeave(index) {
      // 移除hover的事件
      for (let i = 0; i < this.goodsUnit[index].collocationItems.length; i++) {
        const element = this.goodsUnit[index].collocationItems[i];
        element["hoverRow"] = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.goodsSpecList {
  .goodsSpecItem {
    display: flex;
    border-bottom: 3px dashed #ccc;
    padding: 15px 0;
    margin-right: 10px;
    .left {
      width: 95%;
      //属性名称tag
      .el-tag.el-tag--info {
        color: #333;
      }
      //表格(规格商品表格)
      .table {
        //使表格校验时显示，校验通过时隐藏
        ::v-deep .el-form-item {
          padding: 0;
          margin: 0;
        }
        ::v-deep .el-form-item__error {
          position: static;
        }
        //换算单位
        .referUnit {
          text-align: center;
          .el-input,
          .el-select {
            width: 100px;
            margin: 0 2px;
          }
          //图标
          span {
            margin: 5px;
          }
          //去掉下拉框小三角图标
          ::v-deep .el-input.is-disabled .el-input__icon {
            display: none !important;
          }
        }
      }
      //搭配商品表格
      .collocationTable {
        //使表格校验时显示，校验通过时隐藏
        ::v-deep .el-form-item {
          padding: 0;
          margin: 0;
        }
        ::v-deep .el-form-item__error {
          position: static;
        }
      }
    }
    .right {
      width: 50px;
      i {
        font-size: 25px;
        padding: 10px;
        margin: 5px;
        cursor: pointer;
        color: #ff3753;
      }
    }
    //输入框原生属性 /** * 解决el-input设置类型为number时，中文输入法光标上移问题
    ::v-deep .el-input__inner {
      line-height: 1px !important;
    }

    /** *
解决el-input设置类型为number时，去掉输入框后面上下箭头 **/
    ::v-deep input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none !important;
      margin: 0 !important;
    }

    ::v-deep .more.nutrient {
      line-height: 2.5;
    }
  }
}
::v-deep .el-form-item__content {
  margin-left: 0 !important;
}
::v-deep .el-form-item__error {
  position: static;
}

//输入框原生属性
/**
 * 解决el-input设置类型为number时，中文输入法光标上移问题
 **/
::v-deep .el-input__inner {
  line-height: 1px !important;
}

/**
 * 解决el-input设置类型为number时，去掉输入框后面上下箭头
 **/
::v-deep input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
::v-deep .el-input__suffix-inner {
  z-index: 11;
  height: 100%;
  display: inline-block;
  display: flex;
  justify-content: center;
  align-items: center;
}
.daPeiGoods {
  ::v-deep .el-form-item__content {
    flex: 1;
  }
}
</style>
