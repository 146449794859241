var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "goodsSpecList" },
    [
      _vm._l(_vm.goodsUnit, function (item, index) {
        return _c("div", { key: index, staticClass: "goodsSpecItem" }, [
          _c(
            "div",
            { staticClass: "left" },
            [
              _c(
                "el-table",
                {
                  staticClass: "table",
                  staticStyle: { width: "100%" },
                  attrs: { data: item.goodsUnits, border: "" },
                },
                [
                  _c(
                    "el-table-column",
                    { attrs: { align: "center", label: "序号", width: "50" } },
                    [[_c("div", [_vm._v(_vm._s(index + 1))])]],
                    2
                  ),
                  _c(
                    "el-table-column",
                    {
                      attrs: {
                        prop: "barcode",
                        align: "center",
                        label: "商品条码",
                        "min-width": "200",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                index === 0
                                  ? _c(
                                      "el-form-item",
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            disabled: "",
                                            maxlength: "20",
                                          },
                                          model: {
                                            value: scope.row.barcode,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "barcode",
                                                $$v
                                              )
                                            },
                                            expression: "scope.row.barcode",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                !(index === 0)
                                  ? _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          prop:
                                            "goodsUnit[" +
                                            index +
                                            "].goodsUnits[" +
                                            scope.$index +
                                            "].barcode",
                                          rules:
                                            _vm.rules[
                                              "goodsUnit.goodsUnits.barcode"
                                            ],
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: { maxlength: "20" },
                                          model: {
                                            value: scope.row.barcode,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "barcode",
                                                $$v
                                              )
                                            },
                                            expression: "scope.row.barcode",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    },
                    [
                      _c("template", { slot: "header" }, [
                        _c("i", { staticStyle: { color: "#ff4949" } }, [
                          _vm._v("*"),
                        ]),
                        _vm._v(" 商品条码 "),
                      ]),
                    ],
                    2
                  ),
                  _c(
                    "el-table-column",
                    {
                      attrs: {
                        prop: "purPrice",
                        align: "center",
                        label: "进价(元)",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                index === 0
                                  ? _c(
                                      "el-form-item",
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            disabled: "",
                                            type: "number",
                                            maxlength: "7",
                                          },
                                          model: {
                                            value: scope.row.purPrice,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "purPrice",
                                                $$v
                                              )
                                            },
                                            expression: "scope.row.purPrice",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                !(index === 0)
                                  ? _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          prop:
                                            "goodsUnit[" +
                                            index +
                                            "].goodsUnits[" +
                                            scope.$index +
                                            "].purPrice",
                                          rules:
                                            _vm.rules[
                                              "goodsUnit.goodsUnits.purPrice"
                                            ],
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            type: "number",
                                            maxlength: "7",
                                          },
                                          model: {
                                            value: scope.row.purPrice,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "purPrice",
                                                $$v
                                              )
                                            },
                                            expression: "scope.row.purPrice",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    },
                    [
                      _c("template", { slot: "header" }, [
                        _c("i", { staticStyle: { color: "#ff4949" } }, [
                          _vm._v("*"),
                        ]),
                        _vm._v(" 进价(元) "),
                      ]),
                    ],
                    2
                  ),
                  _c(
                    "el-table-column",
                    {
                      attrs: {
                        prop: "salePrice",
                        align: "center",
                        label: "售价(元)",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                index === 0
                                  ? _c(
                                      "el-form-item",
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            disabled: "",
                                            type: "number",
                                            maxlength: "7",
                                          },
                                          model: {
                                            value: scope.row.salePrice,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "salePrice",
                                                $$v
                                              )
                                            },
                                            expression: "scope.row.salePrice",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                !(index === 0)
                                  ? _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          prop:
                                            "goodsUnit[" +
                                            index +
                                            "].goodsUnits[" +
                                            scope.$index +
                                            "].salePrice",
                                          rules:
                                            _vm.rules[
                                              "goodsUnit.goodsUnits.salePrice"
                                            ],
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            type: "number",
                                            maxlength: "7",
                                          },
                                          model: {
                                            value: scope.row.salePrice,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "salePrice",
                                                $$v
                                              )
                                            },
                                            expression: "scope.row.salePrice",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    },
                    [
                      _c("template", { slot: "header" }, [
                        _c("i", { staticStyle: { color: "#ff4949" } }, [
                          _vm._v("*"),
                        ]),
                        _vm._v(" 售价(元) "),
                      ]),
                    ],
                    2
                  ),
                  _c(
                    "el-table-column",
                    {
                      attrs: {
                        prop: "unitName",
                        align: "center",
                        label: "单位",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                index === 0
                                  ? _c(
                                      "el-form-item",
                                      [
                                        _c("el-input", {
                                          attrs: { disabled: "" },
                                          model: {
                                            value: scope.row.unitName,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "unitName",
                                                $$v
                                              )
                                            },
                                            expression: "scope.row.unitName",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                !(index === 0)
                                  ? _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          prop:
                                            "goodsUnit[" +
                                            index +
                                            "].goodsUnits[" +
                                            scope.$index +
                                            "].unitName",
                                          rules:
                                            _vm.rules[
                                              "goodsUnit.goodsUnits.unitName"
                                            ],
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "x-f" },
                                          [
                                            _c(
                                              "el-select",
                                              {
                                                attrs: {
                                                  placeholder: "请选择",
                                                },
                                                on: {
                                                  blur: function ($event) {
                                                    return _vm.$parent.$parent.validateField(
                                                      "goodsUnit[" +
                                                        index +
                                                        "].goodsUnits[" +
                                                        scope.$index +
                                                        "].unitName"
                                                    )
                                                  },
                                                  change: function ($event) {
                                                    return _vm.unitNameChange(
                                                      $event,
                                                      scope.row
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value: scope.row.unitName,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      scope.row,
                                                      "unitName",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "scope.row.unitName",
                                                },
                                              },
                                              _vm._l(
                                                _vm.unitList,
                                                function (item) {
                                                  return _c("el-option", {
                                                    key: item.dictCode,
                                                    attrs: {
                                                      label: item.dictLabel,
                                                      value: item.dictLabel,
                                                    },
                                                  })
                                                }
                                              ),
                                              1
                                            ),
                                            _c("i", {
                                              staticClass:
                                                "el-icon-edit marL10",
                                              staticStyle: {
                                                color: "#85cafc",
                                                fontsize: "16px",
                                                cursor: "pointer",
                                              },
                                              on: { click: _vm.setUnit },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    },
                    [
                      _c("template", { slot: "header" }, [
                        _c("i", { staticStyle: { color: "#ff4949" } }, [
                          _vm._v("*"),
                        ]),
                        _vm._v(" 单位 "),
                      ]),
                    ],
                    2
                  ),
                  _c(
                    "el-table-column",
                    {
                      attrs: {
                        prop: "goodsSpec",
                        align: "center",
                        label: "规格",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                index === 0
                                  ? _c(
                                      "el-form-item",
                                      [
                                        _c("el-input", {
                                          attrs: { disabled: index === 0 },
                                          model: {
                                            value: scope.row.goodsSpec,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "goodsSpec",
                                                $$v
                                              )
                                            },
                                            expression: "scope.row.goodsSpec",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          prop:
                                            "goodsUnit[" +
                                            index +
                                            "].goodsUnits[" +
                                            scope.$index +
                                            "].goodsSpec",
                                          rules:
                                            _vm.rules[
                                              "goodsUnit.goodsUnits.goodsSpec"
                                            ],
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          model: {
                                            value: scope.row.goodsSpec,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "goodsSpec",
                                                $$v
                                              )
                                            },
                                            expression: "scope.row.goodsSpec",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    },
                    [
                      _c("template", { slot: "header" }, [
                        _c("i", { staticStyle: { color: "#ff4949" } }, [
                          _vm._v("*"),
                        ]),
                        _vm._v(" 规格 "),
                      ]),
                    ],
                    2
                  ),
                  index != 0
                    ? _c("el-table-column", {
                        attrs: {
                          prop: "noConvert",
                          align: "center",
                          label: "换算关系",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop:
                                          "goodsUnit[" +
                                          index +
                                          "].goodsUnits[" +
                                          scope.$index +
                                          "].noConvert",
                                      },
                                    },
                                    [
                                      _c("el-switch", {
                                        attrs: {
                                          disabled:
                                            scope.row.createTime &&
                                            scope.row.noConvert == 1,
                                          "active-value": 0,
                                          "inactive-value": 1,
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.noConvertSwitch(
                                              $event,
                                              scope.row
                                            )
                                          },
                                        },
                                        model: {
                                          value: scope.row.noConvert,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "noConvert",
                                              $$v
                                            )
                                          },
                                          expression: "scope.row.noConvert",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      })
                    : _vm._e(),
                  index !== 0
                    ? _c(
                        "el-table-column",
                        {
                          attrs: {
                            prop: "",
                            align: "center",
                            label: "换算单位",
                            width: "400",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "div",
                                      { staticClass: "x-fc referUnit" },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            placeholder: "",
                                            disabled: "",
                                          },
                                          model: {
                                            value: scope.row.unitName,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "unitName",
                                                $$v
                                              )
                                            },
                                            expression: "scope.row.unitName",
                                          },
                                        }),
                                        _c("span", [_vm._v(" = ")]),
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              prop:
                                                "goodsUnit[" +
                                                index +
                                                "].goodsUnits[" +
                                                scope.$index +
                                                "].referUnitAmount",
                                              rules:
                                                _vm.rules[
                                                  "goodsUnit.goodsUnits.referUnitAmount"
                                                ],
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                disabled:
                                                  scope.row.noConvert == 1,
                                                type: "number",
                                              },
                                              model: {
                                                value:
                                                  scope.row.referUnitAmount,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "referUnitAmount",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "scope.row.referUnitAmount",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              prop:
                                                "goodsUnit[" +
                                                index +
                                                "].goodsUnits[" +
                                                scope.$index +
                                                "].referUnitId",
                                              rules:
                                                _vm.rules[
                                                  "goodsUnit.goodsUnits.referUnitId"
                                                ],
                                            },
                                          },
                                          [
                                            _c(
                                              "el-select",
                                              {
                                                staticStyle: { width: "100%" },
                                                attrs: {
                                                  disabled:
                                                    scope.row.noConvert == 1,
                                                  placeholder: "请选择",
                                                },
                                                on: {
                                                  blur: function ($event) {
                                                    return _vm.$parent.$parent.validateField(
                                                      "goodsUnit[" +
                                                        index +
                                                        "].goodsUnits[" +
                                                        scope.$index +
                                                        "].referUnitId"
                                                    )
                                                  },
                                                  focus: function ($event) {
                                                    return _vm.refreshReferUnitData(
                                                      index
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value: scope.row.referUnitId,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      scope.row,
                                                      "referUnitId",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "scope.row.referUnitId",
                                                },
                                              },
                                              _vm._l(
                                                _vm.goodsUnit
                                                  .filter(function (x, i) {
                                                    return (
                                                      scope.row.unitId !==
                                                        x.goodsUnits[0]
                                                          .unitId && i < index
                                                    )
                                                  })
                                                  .map(function (x) {
                                                    return {
                                                      unitName:
                                                        x.goodsUnits[0]
                                                          .goodsSpec +
                                                        "/" +
                                                        x.goodsUnits[0]
                                                          .unitName,
                                                      referUnitId:
                                                        x.goodsUnits[0].unitId,
                                                    }
                                                  }),
                                                function (item) {
                                                  return _c("el-option", {
                                                    key: item.referUnitId,
                                                    attrs: {
                                                      label: item.unitName,
                                                      value: item.referUnitId,
                                                    },
                                                  })
                                                }
                                              ),
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        [
                          _c("template", { slot: "header" }, [
                            _c("i", { staticStyle: { color: "#ff4949" } }, [
                              _vm._v("*"),
                            ]),
                            _vm._v(" 换算单位 "),
                          ]),
                        ],
                        2
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "marT10",
                  attrs: { label: "商品属性:", "label-width": "75px" },
                },
                [
                  _c(
                    "div",
                    { staticClass: "machiningList" },
                    _vm._l(item.machiningGroups, function (v, i) {
                      return _c(
                        "div",
                        { key: i, staticClass: "machiningItem marT10" },
                        [
                          _c(
                            "div",
                            { staticClass: "x-f" },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    size: "mini",
                                    placeholder: "请选择",
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.changeMachining(v)
                                    },
                                    focus: function ($event) {
                                      return _vm.filterMachiningData(index)
                                    },
                                  },
                                  model: {
                                    value: v.machiningGroupId,
                                    callback: function ($$v) {
                                      _vm.$set(v, "machiningGroupId", $$v)
                                    },
                                    expression: "v.machiningGroupId",
                                  },
                                },
                                _vm._l(_vm.curMachiningData, function (item) {
                                  return _c("el-option", {
                                    key: item.machiningGroupId,
                                    attrs: {
                                      label: item.machiningGroupName,
                                      value: item.machiningGroupId,
                                    },
                                  })
                                }),
                                1
                              ),
                              _c(
                                "el-button",
                                {
                                  staticClass: "marL10",
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleMachiningGroup(
                                        "del",
                                        index,
                                        i
                                      )
                                    },
                                  },
                                },
                                [_vm._v("删除")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "marT10 x-fs" },
                            [
                              _vm._l(v.machiningItems, function (j, x) {
                                return _c(
                                  "el-tag",
                                  {
                                    key: j.machiningId,
                                    staticClass: "marR10 marB10",
                                    attrs: {
                                      closable: "",
                                      type: "info",
                                      color: "#f2f2f2",
                                    },
                                    on: {
                                      close: function ($event) {
                                        return _vm.handleMachining(
                                          "del",
                                          index,
                                          i,
                                          x
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          j.machiningName +
                                            " ￥" +
                                            j.machiningPrice
                                        )
                                    ),
                                  ]
                                )
                              }),
                              v.machiningGroupId
                                ? _c("el-button", {
                                    staticClass: "addMachiningBtn",
                                    attrs: {
                                      size: "mini",
                                      icon: "el-icon-plus",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleMachining(
                                          "add",
                                          index,
                                          i,
                                          "",
                                          v.machiningGroupId
                                        )
                                      },
                                    },
                                  })
                                : _vm._e(),
                            ],
                            2
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "el-icon-plus",
                      attrs: { size: "mini" },
                      on: {
                        click: function ($event) {
                          return _vm.handleMachiningGroup("add", index)
                        },
                      },
                    },
                    [_vm._v("添加属性")]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "marT10 x-x daPeiGoods",
                  attrs: { label: "搭配商品:", "label-width": "75px" },
                },
                [
                  item.collocationItems.length <= 0
                    ? _c(
                        "el-button",
                        {
                          staticClass: "el-icon-plus",
                          attrs: { size: "mini" },
                          on: {
                            click: function ($event) {
                              return _vm.handleCollocationTableRow(
                                "push",
                                index,
                                0
                              )
                            },
                          },
                        },
                        [_vm._v("添加商品")]
                      )
                    : _vm._e(),
                  item.collocationItems.length > 0
                    ? _c(
                        "el-table",
                        {
                          staticClass: "collocationTable marT10 flex1",
                          attrs: { data: item.collocationItems, border: "" },
                          on: {
                            "cell-mouse-enter": function ($event) {
                              return _vm.cellMouseEnter($event, index)
                            },
                            "cell-mouse-leave": function ($event) {
                              return _vm.cellMouseLeave(index)
                            },
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: { align: "center", width: "80" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      scope.row.hoverRow
                                        ? _c("i", {
                                            staticClass:
                                              "el-icon-circle-plus operatePush",
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleCollocationTableRow(
                                                  "push",
                                                  index,
                                                  scope.$index
                                                )
                                              },
                                            },
                                          })
                                        : _vm._e(),
                                      scope.row.hoverRow
                                        ? _c("i", {
                                            staticClass:
                                              "el-icon-remove operateDel",
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleCollocationTableRow(
                                                  "del",
                                                  index,
                                                  scope.$index
                                                )
                                              },
                                            },
                                          })
                                        : _vm._e(),
                                      !scope.row.hoverRow
                                        ? _c("div", [
                                            _vm._v(_vm._s(scope.$index + 1)),
                                          ])
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                          _c(
                            "el-table-column",
                            {
                              attrs: {
                                label: "商品编码",
                                width: "260",
                                align: "center",
                                prop: "goodsNo",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              prop:
                                                "goodsUnit[" +
                                                index +
                                                "].collocationItems[" +
                                                scope.$index +
                                                "].goodsNo",
                                              rules:
                                                _vm.rules[
                                                  "goodsUnit.collocationItems.goodsNo"
                                                ],
                                            },
                                          },
                                          [
                                            _c(
                                              "el-input",
                                              {
                                                nativeOn: {
                                                  keyup: function ($event) {
                                                    if (
                                                      !$event.type.indexOf(
                                                        "key"
                                                      ) &&
                                                      _vm._k(
                                                        $event.keyCode,
                                                        "enter",
                                                        13,
                                                        $event.key,
                                                        "Enter"
                                                      )
                                                    ) {
                                                      return null
                                                    }
                                                    return _vm.collocationTableKeyup(
                                                      $event,
                                                      index,
                                                      scope.$index
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value: scope.row.goodsNo,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      scope.row,
                                                      "goodsNo",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "scope.row.goodsNo",
                                                },
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "el-icon-more more",
                                                  attrs: { slot: "suffix" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.openSelectGoods(
                                                        index
                                                      )
                                                    },
                                                  },
                                                  slot: "suffix",
                                                }),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [
                              _c("template", { slot: "header" }, [
                                _c("i", { staticStyle: { color: "#ff4949" } }, [
                                  _vm._v("*"),
                                ]),
                                _vm._v(" 商品编码 "),
                              ]),
                            ],
                            2
                          ),
                          _c("el-table-column", {
                            attrs: {
                              label: "商品名称",
                              align: "center",
                              prop: "goodsName",
                              "show-overflow-tooltip": "",
                              width: "200",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "商品条码",
                              width: "200",
                              align: "center",
                              prop: "barcode",
                              "show-overflow-tooltip": "",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "规格",
                              width: "160",
                              align: "center",
                              prop: "goodsSpec",
                              "show-overflow-tooltip": "",
                            },
                          }),
                          _c(
                            "el-table-column",
                            {
                              attrs: {
                                label: "单位",
                                width: "160",
                                align: "center",
                                prop: "unitId",
                                "show-overflow-tooltip": "",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              prop:
                                                "goodsUnit[" +
                                                index +
                                                "].collocationItems[" +
                                                scope.$index +
                                                "].unitId",
                                              rules:
                                                _vm.rules[
                                                  "goodsUnit.collocationItems.unitId"
                                                ],
                                            },
                                          },
                                          [
                                            _c(
                                              "el-select",
                                              {
                                                on: {
                                                  blur: function ($event) {
                                                    return _vm.$parent.$parent.validateField(
                                                      "goodsUnit[" +
                                                        index +
                                                        "].collocationItems[" +
                                                        scope.$index +
                                                        "].unitId"
                                                    )
                                                  },
                                                  change: function ($event) {
                                                    return _vm.changeUnitPrice(
                                                      scope.row
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value: scope.row.unitId,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      scope.row,
                                                      "unitId",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "scope.row.unitId",
                                                },
                                              },
                                              _vm._l(
                                                scope.row.units,
                                                function (item) {
                                                  return _c("el-option", {
                                                    key: item.unitId,
                                                    attrs: {
                                                      label: item.unitName,
                                                      value: item.unitId,
                                                    },
                                                  })
                                                }
                                              ),
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [
                              _c("template", { slot: "header" }, [
                                _c("i", { staticStyle: { color: "#ff4949" } }, [
                                  _vm._v("*"),
                                ]),
                                _vm._v(" 单位 "),
                              ]),
                            ],
                            2
                          ),
                          _c("el-table-column", {
                            attrs: {
                              label: "标准售价(元)",
                              width: "120",
                              align: "center",
                              prop: "salePrice",
                              "show-overflow-tooltip": "",
                            },
                          }),
                          _c(
                            "el-table-column",
                            {
                              attrs: {
                                label: "数量",
                                align: "center",
                                prop: "goodsQty",
                                "show-overflow-tooltip": "",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              prop:
                                                "goodsUnit[" +
                                                index +
                                                "].collocationItems[" +
                                                scope.$index +
                                                "].goodsQty",
                                              rules:
                                                _vm.rules[
                                                  "goodsUnit.collocationItems.goodsQty"
                                                ],
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              model: {
                                                value: scope.row.goodsQty,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "goodsQty",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "scope.row.goodsQty",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [
                              _c("template", { slot: "header" }, [
                                _c("i", { staticStyle: { color: "#ff4949" } }, [
                                  _vm._v("*"),
                                ]),
                                _vm._v(" 数量 "),
                              ]),
                            ],
                            2
                          ),
                          _c(
                            "el-table-column",
                            {
                              attrs: {
                                label: "搭配价",
                                align: "center",
                                prop: "goodsPrice",
                                "show-overflow-tooltip": "",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              prop:
                                                "goodsUnit[" +
                                                index +
                                                "].collocationItems[" +
                                                scope.$index +
                                                "].goodsPrice",
                                              rules:
                                                _vm.rules[
                                                  "goodsUnit.collocationItems.goodsPrice"
                                                ],
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              model: {
                                                value: scope.row.goodsPrice,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "goodsPrice",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "scope.row.goodsPrice",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [
                              _c("template", { slot: "header" }, [
                                _c("i", { staticStyle: { color: "#ff4949" } }, [
                                  _vm._v("*"),
                                ]),
                                _vm._v(" 搭配价 "),
                              ]),
                            ],
                            2
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "marT10 x-x daPeiGoods",
                  attrs: { label: "营养成分:", "label-width": "75px" },
                },
                [
                  item.goodsUnitNutrientReqList &&
                  item.goodsUnitNutrientReqList.length <= 0
                    ? _c(
                        "el-button",
                        {
                          staticClass: "el-icon-plus",
                          attrs: { size: "mini" },
                          on: {
                            click: function ($event) {
                              return _vm.handleNutrientTableRow(
                                "push",
                                index,
                                0
                              )
                            },
                          },
                        },
                        [_vm._v("添加营养成分")]
                      )
                    : _vm._e(),
                  item.goodsUnitNutrientReqList &&
                  item.goodsUnitNutrientReqList.length > 0
                    ? _c(
                        "el-table",
                        {
                          staticClass: "collocationTable marT10 flex1",
                          attrs: {
                            border: "",
                            data: item.goodsUnitNutrientReqList,
                            "tooltip-effect": "dark",
                            "before-close": _vm.handleClose,
                          },
                          on: {
                            "cell-mouse-enter": function ($event) {
                              return _vm.cellMouseEnterNutrient($event, index)
                            },
                            "cell-mouse-leave": function ($event) {
                              return _vm.cellMouseLeaveNutrient(index)
                            },
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: { align: "center", width: "80" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      scope.row.hoverRow
                                        ? _c("i", {
                                            staticClass:
                                              "el-icon-circle-plus operatePush",
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleNutrientTableRow(
                                                  "push",
                                                  index,
                                                  scope.$index
                                                )
                                              },
                                            },
                                          })
                                        : _vm._e(),
                                      scope.row.hoverRow
                                        ? _c("i", {
                                            staticClass:
                                              "el-icon-remove operateDel",
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleNutrientTableRow(
                                                  "del",
                                                  index,
                                                  scope.$index
                                                )
                                              },
                                            },
                                          })
                                        : _vm._e(),
                                      !scope.row.hoverRow
                                        ? _c("div", [
                                            _vm._v(_vm._s(scope.$index + 1)),
                                          ])
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                          _c(
                            "el-table-column",
                            {
                              attrs: {
                                prop: "nutrientName",
                                label: "营养物质",
                                align: "center",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "el-input",
                                          {
                                            attrs: { readonly: true },
                                            model: {
                                              value: scope.row.nutrientName,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "nutrientName",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "scope.row.nutrientName",
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "el-icon-more more nutrient",
                                              attrs: { slot: "suffix" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.openNutrients(
                                                    index
                                                  )
                                                },
                                              },
                                              slot: "suffix",
                                            }),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [
                              _c("template", { slot: "header" }, [
                                _c("i", { staticStyle: { color: "#ff4949" } }, [
                                  _vm._v("*"),
                                ]),
                                _vm._v(" 营养物质 "),
                              ]),
                            ],
                            2
                          ),
                          _c(
                            "el-table-column",
                            {
                              attrs: {
                                label: "数量",
                                align: "center",
                                prop: "nutrientQty",
                                "show-overflow-tooltip": "",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              prop:
                                                "goodsUnit[" +
                                                index +
                                                "].goodsUnitNutrientReqList[" +
                                                scope.$index +
                                                "].nutrientQty",
                                              rules:
                                                _vm.rules[
                                                  "goodsUnit.goodsUnitNutrientReqList.nutrientQty"
                                                ],
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              attrs: { type: "number" },
                                              model: {
                                                value: scope.row.nutrientQty,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "nutrientQty",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "scope.row.nutrientQty",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [
                              _c("template", { slot: "header" }, [
                                _c("i", { staticStyle: { color: "#ff4949" } }, [
                                  _vm._v("*"),
                                ]),
                                _vm._v(" 数量 "),
                              ]),
                            ],
                            2
                          ),
                          _c(
                            "el-table-column",
                            {
                              attrs: {
                                label: "单位",
                                align: "center",
                                prop: "nutrientUnit",
                                "show-overflow-tooltip": "",
                              },
                            },
                            [
                              _c("template", { slot: "header" }, [
                                _c("i", { staticStyle: { color: "#ff4949" } }, [
                                  _vm._v("*"),
                                ]),
                                _vm._v(" 单位 "),
                              ]),
                            ],
                            2
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          index !== 0
            ? _c("div", { staticClass: "right" }, [
                _c("i", {
                  staticClass: "el-icon-circle-close",
                  on: {
                    click: function ($event) {
                      return _vm.delGoodsSpec(index)
                    },
                  },
                }),
              ])
            : _vm._e(),
        ])
      }),
      _c("c-dialog", {
        attrs: { title: "营养物质", width: 600, showDialog: _vm.dialogVisible },
        on: {
          "update:showDialog": function ($event) {
            _vm.dialogVisible = $event
          },
          "update:show-dialog": function ($event) {
            _vm.dialogVisible = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c(
                  "el-form",
                  {
                    ref: "refnutrientsForm",
                    attrs: {
                      model: _vm.nutrientsForm,
                      rules: _vm.nutrientsFormRules,
                    },
                  },
                  [
                    _c(
                      "el-table",
                      {
                        ref: "multipleTable",
                        staticStyle: { width: "100%" },
                        attrs: {
                          data: _vm.nutrientsForm.tableData,
                          border: "",
                        },
                        on: {
                          "cell-mouse-enter": _vm.cellMouseEnterDialog,
                          "cell-mouse-leave": _vm.cellMouseLeaveDialog,
                          "selection-change": _vm.handleSelectionChange,
                        },
                      },
                      [
                        _c("el-table-column", {
                          attrs: {
                            type: "selection",
                            width: "55",
                            align: "center",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            align: "center",
                            label: _vm.tableCellLabelDialog,
                            width: "80",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  scope.row.hoverRow
                                    ? _c("i", {
                                        staticClass:
                                          "el-icon-circle-plus operatePush",
                                        on: {
                                          click: function ($event) {
                                            return _vm.rowAvailable(
                                              "push",
                                              scope.$index
                                            )
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                  scope.row.hoverRow
                                    ? _c("i", {
                                        staticClass:
                                          "el-icon-remove operateDel",
                                        on: {
                                          click: function ($event) {
                                            return _vm.rowAvailable(
                                              "del",
                                              scope.$index
                                            )
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                  !scope.row.hoverRow
                                    ? _c("div", [
                                        _vm._v(_vm._s(scope.$index + 1)),
                                      ])
                                    : _vm._e(),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "nutrientName",
                            label: "营养物质",
                            width: "280px",
                            align: "center",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop:
                                          "tableData." +
                                          scope.$index +
                                          ".nutrientName",
                                        rules:
                                          _vm.nutrientsFormRules[
                                            "tableData.nutrientName"
                                          ],
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: { width: "120px" },
                                        model: {
                                          value: scope.row.nutrientName,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "nutrientName",
                                              $$v
                                            )
                                          },
                                          expression: "scope.row.nutrientName",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "nutrientUnit",
                            label: "单位",
                            align: "center",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop:
                                          "tableData." +
                                          scope.$index +
                                          ".nutrientUnit",
                                        rules:
                                          _vm.nutrientsFormRules[
                                            "tableData.nutrientUnit"
                                          ],
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: { width: "40px" },
                                        model: {
                                          value: scope.row.nutrientUnit,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "nutrientUnit",
                                              $$v
                                            )
                                          },
                                          expression: "scope.row.nutrientUnit",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "buttonList",
            fn: function () {
              return [
                _c(
                  "el-button",
                  {
                    on: {
                      click: function ($event) {
                        _vm.dialogVisible = false
                      },
                    },
                  },
                  [_vm._v("取 消")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: { click: _vm.updateGoodNutrient },
                  },
                  [_vm._v("保 存")]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }